import Link from "next/link";
import SvgLogo from "./svg/SvgLogo";

export default function Logo() {
    return (
        <div className="hidden xl:block py-7">
            <Link href="/">
                <a className="inline-flex text-purple-800">
                    <SvgLogo width="166" height="26"/>
                </a>
            </Link>
        </div>
    )
}