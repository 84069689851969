import Nav from "../Nav";
import Footer from "../Footer";

export default function MainLayout({overflow = false, children}) {
    const overflowClass = !overflow ? 'overflow-hidden' : '';

    return (
        <div className="flex flex-col min-h-screen">
            <Nav/>
            <main className={`relative flex-1 pt-[50px] md:pt-[100px] bg-white w-screen ${overflowClass}`}>
                {children}
            </main>
            <Footer/>
        </div>
    )
}