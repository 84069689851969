import Link from "next/link";
import SvgLogo from "./svg/SvgLogo";
import LanguageSwitcherMobile from "./LanguageSwitcherMobile";

export default function LogoMobile({active, onLanguageChange}) {
    return (
        <div className="block xl:hidden w-[166px] h-[87px] relative">
            <div
                className={`absolute py-7 transform transition-all duration-500 ${active ? '-translate-y-full opacity-0' : ''}`}>
                <Link href="/">
                    <a className="inline-flex text-purple-800">
                        <SvgLogo width="166" height="26"/>
                    </a>
                </Link>
            </div>

            <div
                className={`absolute z-50 transform transition-all duration-500 ${active ? '' : '-translate-y-full opacity-0'}`}>
                <LanguageSwitcherMobile onClick={onLanguageChange}/>
            </div>
        </div>
    )
}