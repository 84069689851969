export default function BurgerButton({onClick, activeNav}) {

    return (
        <button className="inline-flex text-0 w-8 h-7 focus:outline-none xl:hidden p-1"
                onClick={onClick}>
            <div className="relative h-full w-full">
            <span
                className={`absolute w-full h-[3px] bg-black top-1/2 left-0 transition-transform duration-200 transform will-change-transform ${activeNav ? 'rotate-45 -translate-y-[1px]' : '-translate-y-2.5'}`}/>
                <span
                    className={`absolute w-full h-[3px] bg-black top-1/2 left-0 transition-opacity duration-200 transform will-change-transform -translate-y-1/2 ${activeNav ? 'opacity-0' : ''}`}/>
                <span
                    className={`absolute w-full h-[3px] bg-black top-1/2 left-0 transition-transform duration-200 transform will-change-transform ${activeNav ? '-rotate-45 -translate-y-[1px]' : 'translate-y-[7px]'}`}/>
                Menu
            </div>
        </button>
    )
}