import Link from "next/link";
import SvgChevronDown from "./svg/SvgChevronDown";
import SvgChevronRight from "./svg/SvgChevronRight";

export default function NavSubmenu({items, children}) {
    return (
        <div
            className="group relative inline-flex items-center gap-2 leading-none mouse-hover:bg-purple-800 mouse-hover:text-white pt-10 pb-6 px-6 mb-4 rounded-bl-[15px] rounded-br-[15px] font-medium cursor-default transition-colors duration-200">
            {children}
            <span className="relative top-0.5">
                <SvgChevronDown width={10} height={6} className="transform group-hover:rotate-180 transition-transform duration-200"/>
            </span>

            <div className="absolute top-full left-0 hidden group-hover:block pt-6">
                <div className="w-screen max-w-[225px] bg-white flex flex-col rounded-[15px] px-7 shadow-submenu">
                    {items.map(({href, label}, key) => {
                        return (
                            <Link href={href} key={`NavSubmenuItem: ${key}`}>
                                <a className="text-black mouse-hover:text-purple-800 flex py-6 border-b last:border-0 justify-between items-center transition-colors duration-200 gap-2 submenu-item">
                                    {label}
                                    <span className="shrink-0 h-2.5 w-1.5 text-purple-800 transition-opacity duration-200">
                                        <SvgChevronRight/>
                                    </span>
                                </a>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}