export default function SvgLogo(props){
    return (
        <svg {...props} viewBox="0 0 166 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M27.0282 6.84846L19.0713 24.5299H12.7563L12.2932 12.9913L7.40959 24.5299H1.0946L0 6.84846H5.09409L5.43089 19.3002L10.4408 6.84846H16.0822L16.5874 19.3002L21.471 6.84846H27.0282Z" fill="currentColor"/>
            <path d="M25.5546 16.9759C25.5546 11.2066 30.4382 6.47491 36.5006 6.47491C41.2158 6.47491 45.1311 9.33881 45.1311 14.4855C45.1311 20.0888 40.5001 25.028 34.1851 25.028C29.2594 24.9865 25.5546 21.9566 25.5546 16.9759ZM39.5739 15.0251C39.5739 12.7423 38.1425 11.2066 35.9112 11.2066C33.0905 11.2066 31.0697 13.6139 31.0697 16.3533C31.0697 18.6361 32.5011 20.2548 34.7745 20.2548C37.6794 20.2133 39.5739 17.64 39.5739 15.0251Z" fill="currentColor"/>
            <path d="M59.2349 6.43335L58.2666 11.4556C55.2775 11.4556 53.5514 12.5762 53.0041 14.9835L51.1096 24.5714H45.6787L49.173 6.88991H54.2671L53.8882 8.75767C55.3196 7.30497 57.2141 6.43335 59.2349 6.43335Z" fill="currentColor"/>
            <path d="M70.0539 15.2741L74.8112 24.5299H69.0856L64.8335 16.1873L63.1916 24.5299H57.7607L62.6443 0H68.0752L65.4229 13.4064L71.8221 6.84846H78.2634L70.0539 15.2741Z" fill="currentColor"/>
            <path d="M78.0533 18.138V10.501H74.9379V6.84845H76.5798C77.7586 6.84845 78.348 6.22587 78.348 5.0222V2.24131H82.558V6.84845H87.2731V10.501H82.4317V17.64C82.4317 19.6737 83.4841 20.7944 85.4207 20.7944H87.3994V24.5299H84.5787C80.2846 24.5299 78.0533 22.0396 78.0533 18.138Z" fill="#FFA800"/>
            <path d="M99.3558 6.4334V10.418C96.2404 10.418 94.2617 12.0782 94.2617 14.444V24.5299H89.9254V6.84845H94.0091V8.84073C95.23 7.42953 97.1245 6.4334 99.3558 6.4334Z" fill="#FFA800"/>
            <path d="M119.143 6.84846V24.5299H115.143V22.1641C113.796 23.9073 111.817 24.945 109.207 24.945C104.071 24.945 100.324 21.0019 100.324 15.7307C100.324 10.418 104.071 6.72394 109.207 6.72394C111.775 6.72394 113.796 7.67857 115.143 9.38031V6.84846H119.143ZM114.975 15.7307C114.975 12.4932 112.87 10.2519 109.755 10.2519C106.808 10.2519 104.618 12.5763 104.618 15.7307C104.618 18.8851 106.808 21.2095 109.755 21.2095C112.87 21.168 114.975 18.9266 114.975 15.7307Z" fill="#FFA800"/>
            <path d="M139.94 6.84846L133.078 24.5299H128.11L121.248 6.84846H125.752L130.594 20.4624L135.435 6.84846H139.94Z" fill="#FFA800"/>
            <path d="M149.96 21.334C152.402 21.334 153.707 20.4209 154.507 18.8436L157.917 21.0019C156.57 23.2017 154.17 24.9865 149.792 24.9865C144.066 24.9865 140.319 21.0019 140.319 15.7307C140.319 10.584 144.361 6.47491 149.497 6.47491C155.138 6.47491 158.59 10.833 158.59 15.3571C158.59 16.0212 158.548 16.6853 158.506 17.0589H144.571C145.203 19.7153 147.266 21.334 149.96 21.334ZM154.507 14.0705C154.044 11.4141 152.191 9.79537 149.665 9.79537C147.139 9.79537 145.161 11.3311 144.613 14.0705H154.507Z" fill="#FFA800"/>
            <path d="M161.622 0H165.958V24.5299H161.622V0Z" fill="#FFA800"/>
        </svg>
    )
}