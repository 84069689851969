export function getLocaleCode(locale) {
    switch (locale) {
        case 'en':
            return 'ENG';
        case 'cs':
            return 'CZE';
        case 'es':
            return 'SPA';
        case 'ro':
            return 'ROM';
        case 'pl':
            return 'POL';
        default:
            return '';
    }
}

export function setLocaleCookies(locale) {
    if (document) {
        document.cookie = `NEXT_LOCALE=${locale}; max-age=31536000; path=/`
    }
}
