import Link from "next/link";
import Container from "./Container";
import NavSubmenu from "./NavSubmenu";
import {useRef, useState} from "react";
import BurgerButton from "./BurgerButton";
import BurgerSubmenu from "./BurgerSubmenu";
import useOnClickOutside from "../hooks/useOnClickOutside";
import {useRouter} from "next/router";
import localized from "../locale/locales";
import LanguageSwitcher from "./LanguageSwitcher";
import Logo from "./Logo";
import LogoMobile from "./LogoMobile";

export default function Nav() {
    const {locale} = useRouter();
    const {common} = localized[locale];

    const navRef = useRef();
    const [activeNav, setActiveNav] = useState(false);

    const toggleBurger = () => setActiveNav(!activeNav);
    const closeBurger = () => setActiveNav(false);

    useOnClickOutside(navRef, closeBurger);

    const navLinks = [
        {
            href: '#',
            label: common.pages.programmes,
            mobileOnly: false,
            submenu: [
                {
                    href: '/programmes/jobs-in-the-netherlands',
                    label: common.pages.programmeNetherlands
                },
                {
                    href: '/programmes/part-time-job-in-amsterdam',
                    label: common.pages.programmeAmsterdam
                },
            ]
        },
        {
            href: '/job-offers',
            label: common.pages.jobOffers,
            mobileOnly: false,
        },
        {
            href: '/how-it-works',
            label: common.pages.howItWorks,
            mobileOnly: false,
        },
        {
            href: '/about-us',
            label: common.pages.aboutUs,
            mobileOnly: false,
        },
        {
            href: '/register',
            label: common.pages.register,
            mobileOnly: true,
        },
        {
            href: '/contacts',
            label: common.pages.contacts,
            mobileOnly: false,
        },
    ];

    return (
        <nav className="fixed top-0 left-0 w-full z-40">
            <div ref={navRef}
                 className="bg-white max-w-[1440px] mx-auto rounded-bl-[35px] rounded-br-[35px] shadow-nav"
            >
                <Container>
                    <div>
                        <div className="flex justify-between flex items-center">
                            <Logo/>
                            <LogoMobile active={activeNav} onLanguageChange={closeBurger}/>

                            <div className="hidden xl:flex items-center gap-5">

                                {navLinks.map(({href, label, submenu, mobileOnly}, key) => {
                                    if (mobileOnly) return null;

                                    if (submenu) {
                                        return (
                                            <NavSubmenu key={`Submenu: ${key}`} items={submenu}>
                                                {label}
                                            </NavSubmenu>
                                        )
                                    }

                                    return (
                                        <Link href={href} key={`NavLink: ${key}`}>
                                            <a className="inline-flex leading-none mouse-hover:bg-purple-800 mouse-hover:text-white pt-10 pb-6 px-6 mb-4 rounded-bl-[15px] rounded-br-[15px] font-medium transition-colors duration-200">{label}</a>
                                        </Link>
                                    )
                                })}

                                <LanguageSwitcher/>

                            </div>

                            <BurgerButton onClick={toggleBurger} activeNav={activeNav}/>
                        </div>

                        <div className={`accordion ${activeNav ? 'accordion-open' : ''}`}>
                            <div className="pb-7 text-center flex flex-col items-center">
                                {navLinks.map(({href, label, submenu}, key) => {
                                    if (submenu) {
                                        return (
                                            <BurgerSubmenu key={`Submenu: ${key}`}
                                                           items={submenu}
                                                           activeNav={activeNav}
                                                           onClick={closeBurger}
                                            >
                                                {label}
                                            </BurgerSubmenu>
                                        )
                                    }

                                    return (
                                        <Link href={href} key={`NavLink: ${key}`}>
                                            <a className="inline-flex justify-center leading-none p-6 font-medium"
                                               onClick={closeBurger}
                                            >
                                                {label}
                                            </a>
                                        </Link>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </Container>
            </div>
        </nav>
    )
}