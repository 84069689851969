import Image from "next/image";

import en from "../public/img/flags/en.svg";
import cs from "../public/img/flags/cs.svg";
import es from "../public/img/flags/es.svg";
import ro from "../public/img/flags/ro.svg";
import pl from "../public/img/flags/pl.svg";

const svg = {
    cs,
    en,
    es,
    ro,
    pl
};

export default function Flag({country, className = ''}) {
    if (!svg[country]) return null;

    return (
        <div className={`w-[25px] h-[25px] relative rounded-full ${className}`}>
            <Image src={svg[country].src} layout="fill"
                   objectFit="cover"
                   objectPosition="center"
                   className="rounded-full"
                   lazyBoundary="700px"
                   alt={country.toUpperCase()}
            />
        </div>
    )
}
