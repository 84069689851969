import SvgChevronDown from "./svg/SvgChevronDown";
import Link from "next/link";
import {useRouter} from "next/router";
import Flag from "./Flag";
import {getLocaleCode, setLocaleCookies} from "../helpers/locales";
import {useRef, useState} from "react";
import useOnClickOutside from "../hooks/useOnClickOutside";

export default function LanguageSwitcherMobile({onClick}) {
    const ref = useRef();
    const [active, setActive] = useState(false);
    const {locale, locales, asPath} = useRouter();

    const handleClick = () => setActive(!active);

    useOnClickOutside(ref, () => setActive(false));

    return (
        <div
            className={`cursor-pointer relative inline-flex items-center gap-3 ${active ? 'bg-purple-800 text-white' : ''} pt-[31px] pb-[19px] px-4 mb-4 rounded-bl-[15px] rounded-br-[15px] font-medium transition-colors duration-200`}
            onClick={handleClick}
            ref={ref}
        >
            <Flag country={locale} className="shrink-0"/>
            <p className="relative -top-px">{getLocaleCode(locale)}</p>
            <span className="relative top-px">
                <SvgChevronDown width={10} height={6}
                                className={`transform ${active ? 'rotate-180' : ''} transition-transform duration-200`}/>
            </span>

            <div className={`absolute top-full right-0 w-full ${active ? 'block' : 'hidden'} pt-2`}>
                <div className="w-full bg-white flex flex-col rounded-[15px] px-4 shadow-submenu">
                    {locales.map((lang, key) => {
                        if (lang === locale) return null;
                        return (
                            <Link href={asPath} locale={lang} key={`NavSubmenuItem: ${key}`}>
                                <a className="relative text-black/40 mouse-hover:text-black flex gap-3  py-4 border-b last:border-0 items-center transition-colors duration-200 submenu-item"
                                   onClick={() => {
                                       setLocaleCookies(lang);
                                       onClick();
                                   }}
                                >
                                    <Flag country={lang} className="shrink-0"/>
                                    <p>
                                        {getLocaleCode(lang)}
                                    </p>
                                </a>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}