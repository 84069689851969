import Link from "next/link";
import SvgChevronDown from "./svg/SvgChevronDown";
import {useEffect, useRef, useState} from "react";

export default function BurgerSubmenu({items, children, activeNav, onClick}) {
    const submenuRef = useRef();
    const [active, setActive] = useState(false);

    const closeSubmenu = () => setActive(false);

    useEffect(() => {
        if (!activeNav) closeSubmenu();
    }, [activeNav]);

    // useOnClickOutside(submenuRef, closeSubmenu);

    return (
        <div className="flex flex-col" ref={submenuRef}>
            <div
                className="relative inline-flex items-center justify-center gap-2 leading-none p-6 font-medium cursor-pointer"
                onClick={() => setActive(!active)}
            >
                {children}
                <span
                    className={`relative top-0.5 transform transition-transform duration-300 ${active ? 'rotate-180' : ''}`}>
                    <SvgChevronDown width={10} height={6}/>
                </span>
            </div>

            <div
                className={`${active ? 'accordion-open' : ''} accordion flex w-full min-w-max bg-white flex-col rounded-[15px] px-7 shadow-submenu transition-all duration-200`}>
                {items.map(({href, label}, key) => {
                    return (
                        <Link href={href} key={`NavSubmenuItem: ${key}`}>
                            <a className="text-black flex py-6 border-b last:border-0 justify-center items-center"
                               onClick={onClick}
                            >
                                {label}
                            </a>
                        </Link>
                    )
                })}

            </div>
        </div>
    )
}