import SvgChevronDown from "./svg/SvgChevronDown";
import Link from "next/link";
import {useRouter} from "next/router";
import Flag from "./Flag";
import {getLocaleCode, setLocaleCookies} from "../helpers/locales";

export default function LanguageSwitcher() {
    const {locale, locales, asPath} = useRouter();

    return (
        <div
            className="group min-w-[140px] relative inline-flex items-center justify-between mouse-hover:bg-purple-800 mouse-hover:text-white pt-9 pb-[19px] px-6 mb-4 rounded-bl-[15px] rounded-br-[15px] font-medium cursor-default transition-colors duration-200">
            <div className="flex-1 flex items-center justify-start gap-3">
                <Flag country={locale} className="shrink-0"/>
                <p>{getLocaleCode(locale)}</p>
            </div>
            <div className="relative top-px">
                <SvgChevronDown width={10} height={6}
                                className="transform group-hover:rotate-180 transition-transform duration-200"/>
            </div>

            <div className="absolute top-full right-0 hidden group-hover:block pt-6">
                <div className="w-screen max-w-[120px] bg-white flex flex-col rounded-[15px] px-4 shadow-submenu">
                    {locales.map((lang, key) => {
                        if (lang === locale) return null;
                        return (
                            <Link href={asPath} locale={lang} key={`NavSubmenuItem: ${key}`}>
                                <a className="text-black/40 mouse-hover:text-black flex gap-3  py-4 border-b last:border-0 items-center transition-colors duration-200 submenu-item"
                                   onClick={() => setLocaleCookies(lang)}
                                >
                                    <Flag country={lang} className="shrink-0"/>
                                    <p>
                                        {getLocaleCode(lang)}
                                    </p>
                                </a>
                            </Link>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}